<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Sales Order">
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="Name"
                        disabled
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <hr>
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(0)" label="Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formSO :territoryCode="territory.code" :customerCategory="customerCategory.id" :selectedSO="selectedSO" :optionTerritory="optionTerritory" v-on:selectSalesOrder="updateSelectedSalesOrder"></formSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(1)" label="SO Tracker">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring :territory="territory"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"></monitoring>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import newSO from "./new_so/datatable.vue";
import formSO from "./form/form.vue";
import monitoring from "./monitoring/index.vue";

export default {
    components: {
        newSO,
        formSO,
        monitoring
    },
    mounted() {
        if((typeof this.$userLogin.customer_id) == 'undefined' || (typeof this.$userLogin.territory_id) == 'undefined') {
            this.$vs.notify({
                title: "Error",
                text: "You're not customer! ",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-times"
            });
            this.$nextTick(() => {
                this.$router.push("/dashboard");
            })
        }
        this.getOptionTerritory();
    },
    data: () => ({
        tabColor:['primary', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        colorx: "success",
        selectedSO: {}
    }),
    watch: {
        // territory: function(val) {
        //     console.log(val.id);
        //     console.log(val.code);
        // }
    },
    methods: {
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        getOptionTerritory() {
            this.$http.get("/api/v1/master/territory/"+this.$userLogin.territory_id).then(resp => {
                this.optionTerritory = [resp.data];
                this.territory = resp.data;
            });
        },
        updateSelectedSalesOrder(salesOrder) {
            this.selectedSO = salesOrder;
            this.changeTab(0);
            // console.log("selected", this.selectedSO);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>