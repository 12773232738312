<template>
    <div>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="getOptionPromotionCode"
        >
            <template slot="tbody">
                <vs-tr :key="index" v-for="(dt, index) in optionPromotionCode" v-bind:class="[selectedPromotionCode.includes(dt.PromoCode) ? 'colored' : '']">                
                    <vs-td>
                        Code : {{dt.promotion.Code}}<br>
                        Name : {{dt.promotion.Name}}<br>
                        Desc : {{dt.promotion.Description}}<br>
                    </vs-td>
                    <vs-td>
                        Code : {{dt.price_rule.Code}}<br>
                        Name : {{dt.price_rule.Name}}<br>                
                    </vs-td>
                    <vs-td>
                        <template>
                            <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectPromotionCode(dt)"/>
                        </template>
                    </vs-td>
                </vs-tr>
            </template>
        </data-table>
    </div>
</template>

<script>
    export default {
        props: ["territoryId", "selectedPromotionCode"],
        components: {
            // 
        },
        mounted(){
            // this.selectedPromotionCode = this.selectedPromotionCode
            // this.getOptionPromotionCode();
        },
        computed: {
            // 
        },
        watch: {
            // 
        },
        data: () => ({
            optionPromotionCode: [{
                "promotion": {
                    "Code" : "",
                    "Description" : "",
                    "Name" : "",
                },
                "price_rule": {
                    "Code": "",
                    "Name": ""
                }
            }],

            params: {
                search: "",
                length: 10,
                page: 1,
                order: "asc",
                sort: "code"
            },
            header: [
                {
                    text: "Promotion",value: "code"
                },
                {
                    text: "Price Rule",sortable: false
                },
                {
                    text: "Action",sortable: false,width: '5%'
                },
            ],

            responseData: {},
        }),
        methods: {
            selectPromotionCode(promotionCode) {
                this.$emit("selectPromotionCode", [promotionCode.promotion.Code]);
                this.$emit("limitItemByPromotion", promotionCode.item);

                this.$vs.notify({
                    title: "Promo selected ("+promotionCode.promotion.Code+")",
                    text: "Clear all selected item",
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            },
            getOptionPromotionCode(params) {
                this.params = params;
                let tempTerritoryIDs = [];
                tempTerritoryIDs.push(this.territoryId);

                // this.$vs.loading();
                this.$http.get("/api/v1/promotion/data-table", {
                    params: {
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.order,
                        sort: params.sort,
                        territory_id: tempTerritoryIDs,
                        is_active_only: 1,
                    }
                }).then(resp => {
                    // console.log("option customer", resp);
                    if (resp.code == 200) {
                        this.optionPromotionCode = resp.data.promotions;
                        this.responseData = resp.data;
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Promotion Code",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check"
                        });
                    }
                    // this.$vs.loading.close();
                });
            },
        },
    };
</script>