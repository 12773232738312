<template>
    <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
            <span>Promotion Code</span>
        </div>
        <div class="vx-col vx-row sm:w-5/6 w-full">
            <div class="vx-col sm:w-5/6 w-full">
                <vs-input class="w-full" v-model="stringPromotionCode" disabled/>
            </div>
            <div class="vx-col sm:w-1/6 w-full">
                <vs-button size="small" class="mr-3 mb-2" @click="browsePromotionCode()">Browse</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["promotionCode"],
        components: {
            // 
        },
        mounted(){
            console.log("field : ", this.promotionCode);
            this.arrayToString(this.promotionCode);
        },
        computed: {
            // 
        },
        watch: {
            promotionCode: function() {
                this.arrayToString(this.promotionCode)
            }
        },
        data: () => ({
            stringPromotionCode: "",
        }),
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            },
            arrayToString(array) {
                this.stringPromotionCode = array.join(', ');
                console.log("string : ", this.stringPromotionCode);
            },
            browsePromotionCode() {
                this.$emit("browsePromotionCode");
            },
        },
    };
</script>
